<template>
  <div class="personal-real-name">
    <div
      class="to-authentication"
      @click="
        $router.push({ path: '/copyrightService/realName/fillPersonalInfo' })
      "
    >
      点击进行实名认证
    </div>
    <div class="description">
      <div class="part1">
        <div class="title">
          <img :src="require('assets/images/real-name.png')" alt="" />
          <span>实名认证</span>
        </div>
        <div class="info">
          <span>
            根据我国相关<span class="blue" @click="handleLawsRegulation"
              >法律法规及规章</span
            >
            要求，需要进行实名认证。
          </span>
        </div>
      </div>
      <div class="part2">
        <div class="title">
          <img :src="require('assets/images/real-name-good.png')" alt="" />
          <span>实名认证好处</span>
        </div>
        <div class="info">
          <span
            >获得铁杆<span class="blue" @click="popFans = true"
              >粉丝</span
            ></span
          >
          <span
            >开通<span class="blue" @click="popPersonalWorks = true"
              >个人作品集</span
            ></span
          >
          <span
            >通过版权作品及知识服务持续获得
            <span class="blue" @click="popPrestige = true">声望值</span>、
            <span class="blue" @click="popCash = true">现金</span>、
            <span class="blue" @click="popCharacter = true">字符</span>等收益
          </span>
        </div>
      </div>
    </div>
    <!--法律法规-->
    <el-dialog
      :visible.sync="popLawsRegulations"
      :modal="false"
      :close-on-click-modal="false"
      title="法律法规及规章"
      class="laws-regulations"
    >
      <div class="content">
        <div class="laws-table">
          <el-table
            ref="multipleTable"
            :data="lawsRegulationsData"
            tooltip-effect="dark"
            height="280"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="40"> </el-table-column>
            <el-table-column prop="fileName" label="文件名称" width="400">
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="footer">
        <div class="footer-btns">
          <div class="footer-btn down" @click="download">下载</div>
          <div class="footer-btn cancel" @click="cancel">取消</div>
        </div>
      </div>
    </el-dialog>
    <!--粉丝-->
    <el-dialog
      :visible.sync="popFans"
      :modal="false"
      :close-on-click-modal="false"
      title="粉丝"
      class="fans"
    >
      <div class="content">
        <div class="title">
          <img :src="require('assets/images/partner.png')" alt="" />
          <span
            >团聚一群志同道合的小伙伴<span class="light">（图例）</span></span
          >
        </div>
        <div class="container">
          <div class="title">全部粉丝 (379)</div>
          <div class="fans-list">
            <div
              class="fans-list-item"
              v-for="(item, index) in fansData"
              :key="index"
            >
              <div class="fans-info">
                <div class="head">
                  <img :src="item.imgUrl" alt="" />
                  <div class="image-vip">
                    <img :src="require('assets/images/user-vip.png')" alt="" />
                  </div>
                </div>
                <div class="content">
                  <span class="name">{{ item.name }}</span>
                  <span class="personal-label">{{ item.personalLabel }}</span>
                </div>
              </div>
              <div class="fans-follow">
                <button
                  class="follow-btn"
                  v-if="!item.followed"
                  @click="clickFollow(index)"
                >
                  关注
                </button>
                <button
                  class="mutual-follow-btn"
                  v-else
                  @click="clickFollow(index)"
                >
                  <img
                    :src="require('assets/images/mutual-follow.png')"
                    alt=""
                  />互相关注
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!--个人作品集-->
    <el-dialog
      :visible.sync="popPersonalWorks"
      :modal="false"
      :close-on-click-modal="false"
      title="个人作品集"
      class="personal-works"
    >
      <div class="content">
        <div class="desc">
          <img :src="require('assets/images/person.png')" alt="" />
          <div class="info">
            <p>
              上传拥有著作权的文字作品，包括学位论文、学术论文、评论文章、心得感悟、
              随笔等。 个人作品集可以为粉丝、用户，提供阅读、下载、
            </p>
            <p>
              上传拥有著作权的文字作品，包括学位论文、学术论文、评论文章、心得感悟、随笔等。
              个人作品集可以为粉丝、用户，提供阅读、下载、推荐、查重、溯源等知识服务，持续获
              得声望值、现金、字符、下载券等收益。<span class="light"
                >(图例)</span
              >
            </p>
          </div>
        </div>
        <div class="illustration">
          <img :src="require('assets/images/user-bg.png')" alt="" />
        </div>
      </div>
    </el-dialog>
    <!--声望值-->
    <el-dialog
      :visible.sync="popPrestige"
      :modal="false"
      :close-on-click-modal="false"
      title="版权作品获取声望值"
      class="prestige"
    >
      <div class="content">
        <div class="desc">
          <img :src="require('assets/images/prestige.png')" alt="" />
          <div class="info">
            <p>
              认证作者的原创话语，在不同作品中被推荐引用，以及在溯源、查重中被匹配，
              按字数累计所得计算获得引用值。同一账号对同一原创话语的引
            </p>
            <p>
              认证作者的原创话语，在不同作品中被推荐引用，以及在溯源、查重中被匹配，
              按字数累计所得计算获得引用值。同一账号对同一原创话语的引用，只计算一次。
              根据作者的引用分值进行总榜、专业领域榜排名，为作者的行业地位提供一项量
              化评价指标。<span class="light">(图例)</span>
            </p>
          </div>
        </div>
        <div class="illustration">
          <img :src="require('assets/images/user-bg.png')" alt="" />
        </div>
      </div>
    </el-dialog>
    <!--现金-->
    <el-dialog
      :visible.sync="popCash"
      :modal="false"
      :close-on-click-modal="false"
      title="版权作品获取现金"
      class="cash"
    >
      <div class="content">
        <div class="desc">
          <img :src="require('assets/images/prestige.png')" alt="" />
          <div class="info">
            <p>
              版权作品通过设置“付费阅读”“付费下载”“现金赞赏”，直接获得版权现金收益。
              例如，王小二《这不是一个普通的草根创业者》版权作品，可以设置阅读付费“10元”人民币，
              也可以设置下载付费“10元”人民币。再如，皇甫无双《亲属被拘24小时内的事项清单》版权作品，
              可以开启“现金赞赏”获得读者赞赏。<span class="light">(图例)</span>
            </p>
          </div>
        </div>
        <div class="illustration">
          <img :src="require('assets/images/user-bg.png')" alt="" />
        </div>
      </div>
    </el-dialog>
    <!--字符-->
    <el-dialog
      :visible.sync="popCharacter"
      :modal="false"
      :close-on-click-modal="false"
      title="版权作品获取字符"
      class="character"
    >
      <div class="content">
        <div class="desc">
          <img :src="require('assets/images/prestige.png')" alt="" />
          <div class="info">
            <p>
              版权作品中的原创文本，可以在查重、溯源中获得对应的字符赠送。例如，
              王小二《这不是一个普通的草根创业者》版权作品中，假定“这不是一个普通
              的草根创业者”被认定为“原创文本”。王小三在查重、溯源中，匹配到其文本中
              有“这不是一个普通的草根创业者”这句话，则王小二可以获得“10”个字符。
              该字符王小二可以在翻译、查重、溯源、核校等中进行消费，也可以直接兑换成为现金。
              <span class="light">(图例)</span>
            </p>
          </div>
        </div>
        <div class="illustration">
          <img :src="require('assets/images/user-bg.png')" alt="" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { findLawsRegulationList } from "api/copyrightService";
export default {
  name: "PersonalRealName",
  data() {
    return {
      //显示法律法规弹窗
      popLawsRegulations: false,
      //显示粉丝弹窗
      popFans: false,
      //显示个人作品集弹窗
      popPersonalWorks: false,
      //显示声望值弹窗
      popPrestige: false,
      //显示现金弹窗
      popCash: false,
      //显示字符弹窗
      popCharacter: false,
      //法律法规文件
      lawsRegulationsData: [
        {
          fileName: "《中华人民共和国网络安全法》",
        },
        {
          fileName: "《中华人民共和国个人信息保护法》",
        },
        {
          fileName: "《中华人民共和国保守国家秘密法》",
        },
        {
          fileName: "《中华人民共和国数据安全法》",
        },
        {
          fileName: "《中华人民共和国电子签名法》",
        },
        {
          fileName: "《中华人民共和国电子商务法》",
        },
        {
          fileName: "《全国人民代表大会常务委员会关于加强网络信息保护的决定》",
        },
        {
          fileName: "《全国人民代表大会常务委员会关于维护互联网安全的决定》",
        },
      ],
      //粉丝数据
      fansData: [
        {
          name: "阿悦",
          personalLabel: "新出的娃娃。风格不一致",
          followed: false,
          imgUrl: require("assets/images/prod-detail3.jpg"),
        },
        {
          name: "妤妤",
          personalLabel: "今天吃什么好呢",
          followed: false,
          imgUrl: require("assets/images/prod-detail2.jpg"),
        },
        {
          name: "ME",
          personalLabel: "闪闪发光的生活，是这样的...",
          followed: true,
          imgUrl: require("assets/images/prod-detail1.jpg"),
        },
        {
          name: "喝luai茶的恐虫",
          personalLabel: "分享生活小技巧，拍照、美食日常达人...",
          followed: true,
          imgUrl: require("assets/images/prod.jpg"),
        },
      ],
      selectFiles : []
    };
  },
  methods: {
    handleSelectionChange(sel) {
      this.selectFiles = sel;
    },
    //下载法律法规
    download() {
      if(this.selectFiles.length == 1){
        location.href = "https://fafs.antiplagiarize.com/" + this.selectFiles[0].fileUrl;
        this.cancel();
      }else{
        this.$message({
          type : "warning",
          message : "请选择一个文件"
        });
      }
    },
    //取消
    cancel() {
      this.popLawsRegulations = false;
    },
    clickFollow(index) {
      this.fansData.forEach((itm, inx) => {
        if (inx === index) {
          itm.followed = !itm.followed;
        }
      });
    },
    //打开法律法规弹窗
    handleLawsRegulation(){
      this.popLawsRegulations = true;
      this.findLawsList();
    },
    //获取法律法规列表
    async findLawsList(){
      const res = await findLawsRegulationList();
      if(res.code == 200){
        this.lawsRegulationsData = res.data;
      }else{
        this.$message({
          type : "error",
          message : res.message
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
//法律法规弹窗
::v-deep .el-dialog__wrapper.laws-regulations .el-dialog {
  @include flex-center(column);
  justify-content: normal;
  align-items: normal;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0 !important;
  width: 489px;
  height: 405px;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-shadow: 0px 0px 7px 0px rgba(129, 135, 144, 0.2);
  border-radius: 5px;
  .el-dialog__header {
    padding: 15px 12px;
    .el-dialog__title {
      font-size: 14px;
      font-weight: 500;
      color: #000000;
    }
    .el-dialog__headerbtn {
      top: 12px;
      right: 11px;
    }
  }
  .el-dialog__body {
    padding: 0 11px 19px 23px;
    @include flex-center(column);
    justify-content: normal;
    align-items: normal;
    flex: 1;
    .content {
      flex: 1;
      .laws-table {
        ::-webkit-scrollbar {
          width: 2px;
          height: 79px;
        }
        ::-webkit-scrollbar-thumb {
          background-color: #4587ff;
        }
        .el-table::before {
          background-color: transparent;
        }
        .el-table .el-table__header-wrapper .el-table_1_column_2 {
          .cell {
            font-size: 14px;
            font-weight: 500;
            color: #999999;
          }
        }
        .el-table td.el-table__cell {
          &:first-child {
            @include noselect;
          }
          border-bottom: none;
          .cell {
            @include ellipsis;
          }
        }
        .el-table th.el-table__cell.is-leaf {
          border-bottom: none;
        }
      }
    }
    .footer {
      @include flex-between(flex-end);
      .footer-btns {
        @include flex-start;
        margin: 10px 9px 0 0;
        .footer-btn {
          @include noselect;
          width: 72px;
          height: 41px;
          border-radius: 3px;
          text-align: center;
          line-height: 41px;
          cursor: pointer;
          &.down {
            background: #4c8cff;
            border: 1px solid #4587ff;
            font-size: 16px;
            font-weight: 500;
            color: #ffffff;
          }
          &.cancel {
            margin-left: 12px;
            background: #ffffff;
            border: 1px solid #cecece;
            font-size: 16px;
            font-weight: 500;
            color: #999999;
          }
        }
      }
    }
  }
}
//粉丝弹窗
::v-deep .el-dialog__wrapper.fans .el-dialog {
  @include flex-center(column);
  justify-content: normal;
  align-items: normal;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0 !important;
  width: 489px;
  height: 405px;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-shadow: 0px 0px 7px 0px rgba(129, 135, 144, 0.2);
  border-radius: 5px;
  .el-dialog__header {
    padding: 15px 12px;
    .el-dialog__title {
      font-size: 14px;
      font-weight: 500;
      color: #000000;
    }
    .el-dialog__headerbtn {
      top: 12px;
      right: 11px;
    }
  }
  .el-dialog__body {
    padding: 6px 31px 35px 20px;
    @include flex-center(column);
    justify-content: normal;
    align-items: normal;
    flex: 1;
    .content {
      flex: 1;
      .title {
        @include flex-start;
        img {
          width: 14px;
          height: 12px;
        }
        span {
          margin-left: 6px;
          font-size: 14px;
          font-weight: 500;
          color: #333333;
          .light {
            color: #999999;
          }
        }
      }
      .container {
        @include flex-center(column);
        justify-content: normal;
        align-items: normal;
        height: 287px;
        margin: 20px 0 0 22px;
        padding: 10px 0 20px 20px;
        border: 1px solid #c7dbff;
        box-sizing: border-box;
        .fans-list {
          flex: 1;
          padding-right: 20px;
          margin-top: 20px;
          overflow-y: scroll;
          &::-webkit-scrollbar {
            width: 2px;
            height: 50px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #4587ff;
          }
          &-item {
            @include flex-start;
            &:first-child ~ .fans-list-item {
              margin-top: 20px;
            }
            .fans-info {
              @include flex-start;
              align-items: normal;
              flex: 1;
              .head {
                position: relative;
                @include flex-center;
                img {
                  width: 50px;
                  height: 50px;
                  border-radius: 50%;
                }
                .image-vip {
                  position: absolute;
                  right: 0;
                  bottom: 0;
                  width: 15px;
                  height: 15px;
                  border-radius: 50%;
                  img {
                    width: 15px;
                    height: 15px;
                  }
                }
              }
              .content {
                @include flex-center(column);
                justify-content: normal;
                align-items: normal;
                flex: 1;
                margin-left: 10px;
                .name {
                  @include ellipsis;
                  width: 100px;
                  margin-top: 8px;
                  font-size: 12px;
                  font-weight: 500;
                  color: #333;
                }
                .personal-label {
                  @include ellipsis;
                  width: 200px;
                  margin-top: 8px;
                  font-size: 12px;
                  font-weight: 500;
                  color: #999;
                }
              }
            }
            .fans-follow {
              button {
                @include noselect;
                outline: none;
                border: none;
                cursor: pointer;
              }
              .follow-btn {
                width: 80px;
                height: 35px;
                background-color: #4587ff;
                border-radius: 20px;
                text-align: center;
                line-height: 35px;
                font-size: 12px;
                color: #fff;
              }
              .mutual-follow-btn {
                @include flex-start;
                width: 80px;
                height: 35px;
                padding: 0;
                background-color: #fff;
                border-radius: 20px;
                border: 2px solid #666;
                font-size: 12px;
                color: #666;
                box-sizing: border-box;
                img {
                  width: 20px;
                  height: 18px;
                  margin-right: 2px;
                }
              }
            }
          }
        }
      }
    }
  }
}
::v-deep .el-dialog__wrapper.personal-works .el-dialog,
::v-deep .el-dialog__wrapper.prestige .el-dialog{
  height:445px !important;
}
//个人作品集弹窗
::v-deep .el-dialog__wrapper.personal-works .el-dialog,
//声望值
::v-deep .el-dialog__wrapper.prestige .el-dialog,
//现金
::v-deep .el-dialog__wrapper.cash .el-dialog,
//字符
::v-deep .el-dialog__wrapper.character .el-dialog {
  @include flex-center(column);
  justify-content: normal;
  align-items: normal;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0 !important;
  width: 489px;
  height: 405px;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-shadow: 0px 0px 7px 0px rgba(129, 135, 144, 0.2);
  border-radius: 5px;
  .el-dialog__header {
    padding: 15px 12px;
    .el-dialog__title {
      font-size: 14px;
      font-weight: 500;
      color: #000000;
    }
    .el-dialog__headerbtn {
      top: 12px;
      right: 11px;
    }
  }
  .el-dialog__body {
    padding: 6px 26px 35px 21px;
    @include flex-center(column);
    justify-content: normal;
    align-items: normal;
    flex: 1;
    .content {
      flex: 1;
      .desc {
        @include flex-start;
        align-items: normal;
        img {
          width: 12px;
          height: 14px;
          margin-top:3px;
        }
        .info {
          line-height:1.5em;
          flex: 1;
          margin-left: 6px;
          p:nth-child(2) {
            margin-top: 20px;
          }
          .light {
            color: #999;
          }
        }
      }
      .illustration {
        margin-left: 18px;
        margin-top: 27px;
        height: 169px;
        border: 1px solid #c7dbff;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
.personal-real-name {
  padding: 14px 40px 0;
  .to-authentication {
    @include noselect;
    width: 152px;
    height: 47px;
    background: #4587ff;
    border: 1px solid #3683f2;
    border-radius: 5px;
    text-align: center;
    line-height: 47px;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
  }
  .description {
    padding: 54px 0 0 20px;
    .part2 {
      margin-top: 40px;
      .info {
        @include flex-center(column);
        justify-content: normal;
        align-items: normal;
      }
    }
    .title {
      @include flex-start;
      img {
        width: 16px;
        height: 15px;
        margin-bottom: 1px;
      }
      span {
        margin-left: 5px;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
      }
    }
    .info {
      margin-top: 8px;
      margin-left: 20px;
      span {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        .blue {
          color: #4587ff;
          cursor: pointer;
        }
        &:first-child ~ span {
          margin-top: 8px;
        }
      }
    }
  }
}
</style>
